<template>
  <div class="home">
    <div class="content-wrap">
      <div v-if="loading == false" class="invoice">

        <router-link to="/" class="logo"><img src="../../assets/images/logo.png" width="250" height="93"></router-link>

        <h3>Mietvertrag</h3>

        <h4>1. Vermieter, Mieter</h4>

        <div class="row row-gutter-20">
          <div class="col-12">
            <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
              <tbody>
                <tr>
                  <td>Vermieter:</td>
                  <td>Standort {{ reservation.pickup_location.name }}</td>
                </tr>
                <tr>
                  <td>Adresse:</td>
                  <td>{{ reservation.pickup_location.street_name }} {{ reservation.pickup_location.street_number }}</td>
                </tr>
                <tr>
                  <td>PLZ + Ort:</td>
                  <td>{{ reservation.pickup_location.zip_code }} {{ reservation.pickup_location.city }}</td>
                </tr>
                <tr>
                  <td>Telefonnummer:</td>
                  <td>{{ reservation.pickup_location.contact_phone }}</td>
                </tr>
                <tr>
                  <td>24h Hotline:</td>
                  <td>0157 836 661 61</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ reservation.pickup_location.contact_email }}</td>
                </tr>
                <tr>
                  <td>Kundennummer:</td>
                  <td></td>
                </tr>

              </tbody>
            </table>
          </div>
          <div class="col-12">
            <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
              <tbody>
                <tr>
                  <td>Firma:</td>
                  <td>{{ reservation.company }}</td>
                </tr>
                <tr>
                  <td>Mieter:</td>
                  <td>{{ reservation.first_name }} {{ reservation.last_name }}</td>
                </tr>
                <tr>
                  <td>Adresse:</td>
                  <td>{{ reservation.street_name }} {{ reservation.street_number }}</td>
                </tr>
                <tr>
                  <td>PLZ + Ort:</td>
                  <td>{{ reservation.zip_code }} {{ reservation.city }}</td>
                </tr>
                <tr>
                  <td>Geburtsdatum:</td>
                  <td>{{ reservation.birth_date }}</td>
                </tr>
                <tr>
                  <td>Telefonnummer:</td>
                  <td>{{ reservation.phone_number }}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ reservation.email }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>

        <h4>2. Mietfahrzeug, Mietdauer, Extras</h4>

        <div class="row row-gutter-20">
          <div class="col-12">
            <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
              <tbody>
                <tr>
                  <td>Fahrzeugkategorie:</td>
                  <td>{{ reservation.category.name }}</td>
                </tr>
                <tr>
                  <td>Hersteller:</td>
                  <td>{{ reservation.make_name }}</td>
                </tr>
                <tr>
                  <td>Modell:</td>
                  <td>{{ reservation.vehicle.vehicle_model }}</td>
                </tr>
                <tr>
                  <td>Kilometerstand:</td>
                  <td>{{ reservation.vehicle.mileage }} km</td>
                </tr>
                <tr>
                  <td>Amtl. Kennzeichen:</td>
                  <td>{{ reservation.vehicle.license_plate }}</td>
                </tr>
                <tr>
                  <td>FIN:</td>
                  <td>{{ reservation.vehicle.fin }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12">
            <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
              <tbody>
                <tr>
                  <td>Mietbeginn:</td>
                  <td>{{ reservation.pickup_timestamp }}</td>
                </tr>
                <tr>
                  <td>Mietende:</td>
                  <td>{{ reservation.return_timestamp }}</td>
                </tr>
                <tr>
                  <td>Extra 1:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Extra 2:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Extra 3:</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Extra 4:</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h4>3. Mietpreis, Kaution</h4>

        <table class="table" style="border: 1px solid #dee2e6;margin-bottom:50px">
          <tbody>
            <tr>
              <td width="30%">Mietpreis:</td>
              <td>{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(reservation.sum_gross) }}</td>
            </tr>
            <tr>
              <td>Kaution:</td>
              <td>{{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(reservation.deposit) }}</td>
            </tr>
            <tr>
              <td>Inklusivkilometer:</td>
              <td><span v-if="reservation.res_items[2]">{{ reservation.res_items[2].description }}</span></td>
            </tr>
            <tr>
              <td>Preis pro km mehr:</td>
              <td>{{ price_km }}</td>
            </tr>
          </tbody>
        </table>

        <h4>4. Versicherung</h4>

        <p><span v-if="reservation.res_items[1].description">{{ reservation.res_items[1].description }}</span><span v-else>Für das Fahrzeug besteht eine Haftpflichtversicherung. Es kann eine Teilkasko- oder Vollkaskoversicherung erstellt werden. Die Selbstbeteiligung beträgt ______________ €. Diese gilt pro Schadensfall.</span></p>

        <h4>5. Sauberkeit des Fahrzeugs</h4>

        <p>Dieses Fahrzeug wurde Ihnen sauber übergeben. Bei stark verschmutzten Fahrzeugen erheben wir eine Reinigungspauschale in Höhe von 50,00 € zzgl. MwSt.. Außerdem ist es Ihnen untersagt Tiere ohne vorherige Absprache zu transportieren. Bei einem Verstoß werden wir eine Pauschale in Höhe von 50,00 € erheben.</p>

        <h4>6. Allgemeine Vermietbedingungen, Stornierungskosten, Verkehrsverstöße</h4>

        <p>Die AVBs sind auf unsere Website einzusehen. Bei einer Stornierung fallen Stornokosten in Höhe von 50% des Mietpreises an. Im Falle einer Verkehrsordnungswidrigkeit leiten wir der diensthabenden Behörde die Daten des Mieters weiter. Mit der Unterschrift akzeptieren Sie die AVBs.</p>

        <div class="row row-gutter-20" style="margin: 100px 0 100px 0">
          <div class="col-12">
            <p style="font-size:14px;font-weight:600;margin-bottom:80px">{{ reservation.pickup_location.name }}, den {{ reservation.pickup_timestamp.split(" ")[0] }}</p>
            <p style="border-bottom:2px solid #ccc;width:80%;"></p>
            <p>Standort, Datum, Mieter</p>
          </div>
          <div class="col-12">
            <p style="font-size:14px;font-weight:600;margin-bottom:80px">{{ reservation.pickup_location.name }}, den {{ reservation.pickup_timestamp.split(" ")[0] }}</p>
            <p style="border-bottom:2px solid #ccc;width:80%;"></p>
            <p>Standort, Datum, Vermieter</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios'

export default {
  name: 'invoice',
  data () {
    return {
      loading: true,
      reservation: {},
      price_km: ""
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/reservations/'+this.$route.params.uuid, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.reservation = response.data.reservation;

        var regex = /\d/g;
        if (regex.test(this.reservation.res_items[2].name)) {
          this.price_km = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.reservation.extra_km);
        } else {
          this.price_km = "Unbegrenzt inklusive"
        }

        this.loading = false;
      })
      .catch(error => {
        console.log(error);
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

  .invoice {
    margin: auto;
    max-width: 1200px;

    h3 {
      font-size: 28px;
      margin-bottom: 30px;
    }

    h4 {
      font-size: 22px;
    }
  }

</style>
